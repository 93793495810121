import PropTypes from 'prop-types';
import React, { useState } from 'react';

/**
 * Component to render different types of questions based on the question type.
 *
 * @param {Object} props - The component props.
 * @param {Object} props.question - The question object containing details about the question.
 * @param {number} props.question.question_type - The type of the question (0 for Select, 1 for Free Text, 2 for Country).
 * @param {Function} props.setValue - The function to set the value of the question.
 * @returns {JSX.Element|null} The appropriate question component based on the question type, or null if the type is not recognized.
 */
export const Question = ({ question, isComplianceCheckNeeded = false, setValue }) => {
    switch (question.question_type) {
        case 0:
            return <SelectQuestion question={question} isComplianceCheckNeeded={isComplianceCheckNeeded} setValue={setValue} />;
        case 1:
            return <FreeTextQuestion question={question} isComplianceCheckNeeded={isComplianceCheckNeeded} setValue={setValue} />;
        case 2:
            return <CountryQuestion question={question} isComplianceCheckNeeded={isComplianceCheckNeeded} setValue={setValue} />;
        default:
            return null;
    }
};

export default Question;

Question.propTypes = {
    question: PropTypes.object.isRequired,
    isComplianceCheckNeeded: PropTypes.bool,
    setValue: PropTypes.func.isRequired,
};

/**
 * SelectQuestion component renders a question with selectable options.
 * It supports both single-select and multi-select types.
 *
 * @param {Object} props - The component props.
 * @param {Object} props.question - The question object containing details like title, description, options, etc.
 * @param {Function} props.setValue - The function to update the selected value(s) for the question.
 *
 * @returns {JSX.Element} The rendered SelectQuestion component.
 */
const SelectQuestion = ({ question, isComplianceCheckNeeded, setValue }) => {
    const [selectedValues, setSelectedValues] = useState([]);
    const [validationMessage, setValidationMessage] = useState("");

    const handleOptionChange = (option) => {
        if (question.select_type === 1) {
            // Handle multi-select
            const updatedValues = selectedValues.some((value) => value.id === option.id)
                ? selectedValues.filter((value) => value.id !== option.id) // Remove if already selected
                : [...selectedValues, option]; // Add if not selected

            setSelectedValues(updatedValues);
            setValue(question.id, updatedValues);

            // Check compliance for Multi select
            if (isComplianceCheckNeeded) {
                const hasInvalidOption = updatedValues.some(
                    (option) => option.valid_compliance_option === false || option.valid_compliance_option === "false"
                );

                if (hasInvalidOption) {
                    setValidationMessage(question.validation_message);
                } else {
                    setValidationMessage("");
                }
            }
        } else {
            // Handle single select
            setSelectedValues([option]); // Replace with the single selected object
            setValue(question.id, option);

            // Check compliance for Single select
            if (isComplianceCheckNeeded && (option.valid_compliance_option === false || option.valid_compliance_option === "false")) {
                setValidationMessage(question.validation_message);
            } else {
                setValidationMessage("");
            }
        }
    };

    return (
        <div className="row">
            <h4>{question.title}</h4>
            { question.description && <p>{question.description}</p> }
            { question.extended_description && <p>{question.extended_description}</p> }

            <div className={question.options.length <= 2 ? "radio-group inline" : "radio-group"}>
                {question.options.map((option) => (
                    <React.Fragment key={option.id}>
                        <input
                            type={question.select_type === 1 ? "checkbox" : "radio"}
                            id={option.id}
                            name={question.id}
                            value={option.id}
                            checked={selectedValues.some((value) => value.id === option.id)}
                            onChange={() => handleOptionChange(option)}
                        />
                        <label htmlFor={option.id}><span className="radio"><svg width="20px" height="20px"><circle cx="8" cy="8" r="6"></circle></svg></span>{option.text}</label>

                        {selectedValues.some((value) => value.id === option.id) &&
                            option.follow_up_questions &&
                            option.follow_up_questions.map((followUpQuestion) => (
                                <div key={followUpQuestion.id} className="row">
                                    <Question question={followUpQuestion} isComplianceCheckNeeded={option.is_compliance_check_needed} setValue={setValue} />
                                </div>
                            ))}
                    </React.Fragment>
                ))}
            </div>

            {/* Show validation message if it exists */}
            {validationMessage && <p style={{ color: "red" }}>{validationMessage}</p>}
        </div>
    );
};

SelectQuestion.propTypes = {
    question: PropTypes.object.isRequired,
    isComplianceCheckNeeded: PropTypes.bool.isRequired,
    setValue: PropTypes.func.isRequired,
};

/**
 * FreeTextQuestion component renders a text input field with validation for a given question.
 *
 * @param {Object} props - The component props.
 * @param {Object} props.question - The question object containing details about the question.
 * @param {string} props.question.id - The unique identifier for the question.
 * @param {string} props.question.title - The title of the question.
 * @param {string} [props.question.description] - The description of the question.
 * @param {string} [props.question.extended_description] - The extended description of the question.
 * @param {number} [props.question.max_length] - The maximum length of the input value.
 * @param {boolean} [props.question.is_mandatory] - Indicates if the question is mandatory.
 * @param {Function} props.setValue - The function to set the value of the question.
 *
 * @returns {JSX.Element} The rendered FreeTextQuestion component.
 */
const FreeTextQuestion = ({ question, setValue }) => {
    const [inputValue, setInputValue] = useState("");
    const [validationMessage, setValidationMessage] = useState("");

    const handleInputChange = (e) => {
        const value = e.target.value;

        if (question.max_length > 0 && value.length > question.max_length) {
            setValidationMessage(`Maximalt antal tecken är ${question.max_length}.`);
        } else {
            setValidationMessage("");
        }

        setInputValue(value);
        setValue(question.id, value);
    };

    const handleBlur = () => {
        // DEBUG: set all as mandatory
        question.is_mandatory = true;
        // END DEBUG
        if (question.is_mandatory && inputValue.trim() === "") {
            setValidationMessage("Detta fält är obligatoriskt.");
        } else if (inputValue.trim() !== "" && question.max_length > 0 && inputValue.length > question.max_length) {
            setValidationMessage(`Maximalt antal tecken är ${question.max_length}.`);
        } else {
            setValidationMessage("");
        }
    };

    return (
        <div>
            <label htmlFor={question.id}>{question.title}</label>
            <input
                type="text"
                id={question.id}
                name={question.id}
                value={inputValue}
                onChange={handleInputChange}
                onBlur={handleBlur}
            />

            {/* Show validation message if it exists */}
            {validationMessage && <p style={{ color: "red" }}>{validationMessage}</p>}
        </div>
    );
};

FreeTextQuestion.propTypes = {
    question: PropTypes.object.isRequired,
    setValue: PropTypes.func.isRequired,
};

/**
 * CountryQuestion component renders a question with selectable options.
 * It supports both single-select and multi-select options.
 *
 * @param {Object} props - The component props.
 * @param {Object} props.question - The question object containing details about the question.
 * @param {string} props.question.id - The unique identifier for the question.
 * @param {string} props.question.title - The title of the question.
 * @param {string} [props.question.description] - The description of the question.
 * @param {string} [props.question.extended_description] - The extended description of the question.
 * @param {number} props.question.select_type - The type of selection (1 for multi-select, otherwise single-select).
 * @param {Array} props.question.options - The list of options for the question.
 * @param {string} props.question.options[].id - The unique identifier for the option.
 * @param {string} props.question.options[].text - The display text for the option.
 * @param {boolean} [props.question.options[].valid_compliance_option] - Indicates if the option is a valid compliance option.
 * @param {string} [props.question.validation_message] - The validation message to display if the selected option is not valid.
 * @param {Function} props.setValue - The function to set the selected value(s) for the question.
 *
 * @returns {JSX.Element} The rendered CountryQuestion component.
 */
const CountryQuestion = ({ question, isComplianceCheckNeeded, setValue }) => {
    const [selectedValues, setSelectedValues] = useState([]);
    const [validationMessage, setValidationMessage] = useState('');

    const handleOptionChange = (event) => {
        const selectedOption = question.options.find(
            (option) => option.id === event.target.value
        );

        if (selectedOption) {
            if (question.select_type === 1) {
                // Multi select
                const updatedValues = selectedValues.some((value) => value.id === selectedOption.id)
                    ? selectedValues.filter((value) => value.id !== selectedOption.id)
                    : [...selectedValues, selectedOption];

                setSelectedValues(updatedValues);
                setValue(question.id, updatedValues);

                // Check compliance for Multi select
                if (isComplianceCheckNeeded) {
                    const hasInvalidOption = updatedValues.some(
                        (option) => option.valid_compliance_option === false || option.valid_compliance_option === "false"
                    );

                    if (hasInvalidOption) {
                        setValidationMessage(question.validation_message);
                    } else {
                        setValidationMessage("");
                    }
                }
            } else {
                // Single select
                setSelectedValues([selectedOption]);
                setValue(question.id, selectedOption);

                // Check compliance for Single select
                if (isComplianceCheckNeeded && (selectedOption.valid_compliance_option === false || selectedOption.valid_compliance_option === "false")) {
                    setValidationMessage(question.validation_message);
                } else {
                    setValidationMessage("");
                }
            }
        }
    };

    return (
        <div>
            <h4>{question.title}</h4>
            { question.description && <p>{question.description}</p> }
            { question.extended_description && <p>{question.extended_description}</p> }

            <select
                id={question.id}
                name={question.id}
                multiple={question.select_type === 1}
                value={question.select_type === 1 ? selectedValues.map((option) => option.id) : selectedValues[0]?.id || ""}
                onChange={handleOptionChange}
            >
                <option value="">Select</option>
                {question.options.map((option, optionIndex) => (
                    <option key={optionIndex} value={option.id}>
                        {option.text}
                    </option>
                ))}
            </select>

            {/* Show validation message if it exists */}
            {validationMessage && <p style={{ color: "red" }}>{validationMessage}</p>}
        </div>
    );
};

CountryQuestion.propTypes = {
    question: PropTypes.object.isRequired,
    isComplianceCheckNeeded: PropTypes.bool.isRequired,
    setValue: PropTypes.func.isRequired,
};
