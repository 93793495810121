import React from 'react';
import { createRoot } from 'react-dom/client';
import { KYC } from './kyc/kyc';

/**
 * CreditLimitKYC component is responsible for rendering the KYC (Know Your Customer) form.
 * It fetches the KYC data, updates it, and displays the questions to the user.
 *
 * @returns {JSX.Element} The rendered component.
 */
const CreditLimitKYC = () => {

    /**
     * Redirects the user to the signing page for the credit limit application.
     * The URL is constructed using the `CGILimitAjax.limitApplication` property
     * with "signera" appended to it.
     */
    const sendToSign = () => {
        window.location.assign(`${CGILimitAjax.limitApplication}signera`);
    };

    return (
        <>
            <KYC onSuccess={sendToSign} action="credit_limit_kyc" />
        </>
    );
};

document.addEventListener('DOMContentLoaded', () => {
    const kycDiv = document.getElementById('credit-limit-kyc');

    if (kycDiv) {
        const root = createRoot(kycDiv);
        root.render(
            <div className="application-form-wrapper application-loader-wrapper">
                <div className="application-form">
                    <CreditLimitKYC />
                </div>
            </div>
        );
    }
});
