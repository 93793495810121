import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import loadingGif from '../../images/Eclipse.gif';
import { postAjax, postJson } from '../helpers';
import Question from './question';

/**
 * KYC Component
 *
 * This component handles the Know Your Customer (KYC) process, including fetching KYC questions,
 * validating user input, and submitting the KYC data via AJAX requests.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Function} props.onSuccess - Callback function to be called upon successful KYC submission.
 *
 * @returns {JSX.Element} The rendered KYC component.
 */
export const KYC = ({onSuccess, action = "new_card_kyc"}) => {
    const [kycData, setKycData] = useState(null);
    const [validationErrors, setValidationErrors] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isError, setIsError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        postAjax({ action: 'get_kyc_questions' })
            .then((response) => {
                if (response.status === 401) {
                    postAjax({ action: 'cgi_logout_user' })
                        .then(() => {
                            window.location.assign(CGIApplicationAjax.logIn);
                        }
                    )

                    return Promise.reject('Unauthorized');
                }
                return response.json();
            })
            .then((jsonData) => {
                if (jsonData.success) {
                    setKycData(jsonData.data)
                    setLoading(false);
                } else {
                    console.error('error', jsonData.data);
                }
            })
            .catch((error) => {
                console.error('error', error);
            });
    }, []);

    const validateKycData = () => {
        if (!kycData || !kycData.questions) return false;

        let isValid = true;
        let errors = [];

        const checkQuestions = (questions) => {
            questions.forEach((question) => {
                // DEBUG: set all as mandatory
                question.is_mandatory = true;
                // END DEBUG

                // Mandatory field validation
                if (question.is_mandatory) {
                    if (question.question_type === 1) {
                        // Free text validation
                        if (!question.answer || question.answer.trim() === "") {
                            isValid = false;
                            errors.push(`Fältet "${question.title}" är obligatoriskt.`);
                        }
                    } else if (question.question_type === 0 || question.question_type === 2) {
                        // Select or Country validation
                        if (!question.selected_options || question.selected_options.length === 0) {
                            isValid = false;
                            errors.push(`Fältet "${question.title}" måste ha ett val.`);
                        }
                    }
                }

                // Max length validation
                if (question.question_type === 1 && question.max_length && question.answer.length > question.max_length) {
                    isValid = false;
                    errors.push(`Maximalt antal tecken för "${question.title}" är ${question.max_length}.`);
                }

                // Compliance check validation
                if (question.is_compliance_check_needed && question.follow_up_questions) {
                    question.follow_up_questions.forEach((followUp) => {
                        if (followUp.selected_options && followUp.selected_options.length > 0) {
                            const invalidOptions = followUp.selected_options.filter(option => option.valid_compliance_option === "false");
                            if (invalidOptions.length > 0) {
                                isValid = false;
                                errors.push(followUp.validation_message || `Fältet "${followUp.title}" innehåller ogiltiga val.`);
                            }
                        }
                    });
                }

                // Recursively validate nested questions
                if (question.questions) checkQuestions(question.questions);
                if (question.selected_options) checkQuestions(question.selected_options);
                if (question.follow_up_questions) checkQuestions(question.follow_up_questions);
            });
        };

        checkQuestions(kycData.questions);

        setValidationErrors(errors); // Update state with errors

        return isValid;
    };


    /**
     * Sends KYC (Know Your Customer) data via an AJAX POST request.
     * If the KYC data submission is successful, it sends another AJAX POST request to sign a new card.
     * If the signing request is successful, it redirects the user to the sign document URL.
     *
     * @function
     * @returns {void}
     * @throws {Error} Throws an error if the network response is not ok.
     */
    const postKycData = () => {
        if (!validateKycData(kycData)) {
            return;
        }

        setLoading(true);

        postJson({ action, kyc_data: kycData })
            .then((response) => response.json())
            .then((jsonData) => {
                setLoading(false);

                if (jsonData.success) {
                    setIsError(false);
                    setErrorMessage('');
                    onSuccess();
                } else {
                    setIsError(true);
                    setErrorMessage(jsonData.data.message);
                }
            })
            .catch((error) => {
                setLoading(false);
                setIsError(true);
                setErrorMessage(error);
                console.error('error', error);
            });
    }

    /**
     * Updates the value of a question in the KYC data.
     *
     * @param {number|string} id - The ID of the question to update.
     * @param {any} value - The new value to set for the question.
     */
    const setValue = (id, value) => {
        const updateQuestions = (questions) => {
            return questions.map(question => {
                // Check if this question's id matches
                if (question.id === id) {
                    if (question.question_type === 0 || question.question_type === 2) {
                        question.selected_options = Array.isArray(value) ? value : [value];
                    } else if (question.question_type === 1) {
                        question.answer = value;
                    }
                }

                // Recursively update nested `questions`
                if (question.questions) {
                    question.questions = updateQuestions(question.questions);
                }

                // Recursively update nested `options`
                if (question.options) {
                    question.options = updateQuestions(question.options);
                }

                // Recursively update nested `followUpQuestions`
                if (question.follow_up_questions) {
                    question.follow_up_questions = updateQuestions(question.follow_up_questions);
                }

                return question;
            });
        };

        setKycData(prevState => ({
            ...prevState,
            questions: updateQuestions(prevState.questions)
        }));
    };

    if (loading) {
        return (
            <div className="application-loader"><div className="application-loader-content">
                <img src={loadingGif} className="eclipse" width="120" height="120" />
            </div></div>
        );
    }

    return (
        <div>
            {kycData && kycData.questions.map((kyc, index) => (
                <div className="row" key={index}>
                    <div className="row">
                        <h4>{kyc?.title}</h4>
                        <p>{kyc?.description}</p>
                    </div>

                    {kyc?.questions.map((question, subIndex) => (
                        <div key={subIndex}>
                            <Question question={question} setValue={(id, value) => {
                                setValue(id, value)
                            }} />
                        </div>
                    ))}
                </div>
            ))}

            {validationErrors.length > 0 && (
                <div className="validation-errors">
                    <ul>
                        {validationErrors.map((error, index) => (
                            <li key={index} style={{ color: "red" }}>{error}</li>
                        ))}
                    </ul>
                </div>
            )}

            {kycData && (
                <div className="row button-row">
                    <button onClick={postKycData} className="et_pb_button cgi-submit-input">Skicka in</button>
                </div>
            )}
            { isError && <div className="cgi-status">{errorMessage}</div>}

        </div>
    );
};

KYC.propTypes = {
    onSuccess: PropTypes.func.isRequired,
    action: PropTypes.string
};
